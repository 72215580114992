<template>
  <div class="processing-container container">
    <template v-if="vProcess">
      <div class="text-center">
        <img class="img-x" src="/static/icon/ic_x.png">
        <div class="title main" style="margin:22px 0 12px 0">서비스 개설 중입니다</div>
        <div class="body2 sub3">1분만 기다려주세요</div>
      </div>
      <div class="flex-center" style="margin-top:40px">
        <div class="progress-bar">
          <div class="progress" :style="progress"></div>
        </div>
      </div>
      <div class="flex-center" style="margin-top:40px">
        <slick ref="slick" :options="slickOptions" class="slick-container"
               @beforeChange="handleBeforeChange">
          <img v-for="(i,idx) in [1,2,3]" :key="`img-${idx}`"
               :src="`/static/img/0${i}.png`">
        </slick>
      </div>
      <div class="indicator" style="margin-top:16px">
        <div v-for="(i,idx) in [1,2,3]" :key="`dot-${idx}`"
             class="dot" :class="{'dot-active': idx === currentSlide}"></div>
      </div>
    </template>
    <div class="flex-justify" v-else>
      <div style="width:368px" class="text-center">
        <img class="img-x" src="/static/icon/ic_hand.png">
        <div class="title main" style="margin:22px 0 12px 0">개설이 완료되었습니다</div>
        <div class="body2 sub3">나만의 플랫폼을 만들어보세요!</div>
        <div class="body3 sub2 text-center" style="margin:56px 0 12px 0">관리자 계정정보</div>
        <div class="box-account">
          <div class="body3 sub2">아이디</div>
          <div class="body2-medium sub">{{ user.email }}</div>
          <div class="body3 sub2">비밀번호</div>
          <div class="body2-medium sub">{{ admin_password }}</div>
        </div>
        <a :href="`https://${$route.query.skey}.launchpack.co.kr/office/admin/login?pf=${pf}`" target="_blank">
          <button class="button is-primary button-admin">사이트 관리자 접속</button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import Slick from 'vue-slick';

  const io = require('socket.io-client');

  export default {
    name: "Processing",
    components: {
      Slick
    },
    created() {
      if(this.$store.getters.basket === undefined ||
        Object.keys(this.$store.getters.basket).length === 0) {
        this.$router.back();
        return;
      }
      this.itv = setInterval(() => {
        this.percent += 1.111;
        if (this.percent >= 100) {
          this.percent = 100;
          clearInterval(this.itv);
        }
      }, 1000);
      this.socket = io.connect('https://master-dev.launchpack.co.kr:3000');
      setTimeout(() => {
        this.socket.on('message', (msg) => {
          if (msg.create_service === this.$route.query.skey) {
            this.$store.commit('clearBasket');
            this.admin_password = msg.admin_password;
            this.percent = 100;
            this.pf = encodeURIComponent(`${this.user.username}%102^${this.admin_password}`);
            clearInterval(this.itv);
            setTimeout(() => {
              this.vProcess = false;
            }, 1000);
          }
        });
      }, 500);
    },
    data() {
      return {
        percent: 0,
        slickOptions: {
          centerMode: false,
          variableWidth: false,
          infinite: true,
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 2000,
          arrows: false
        },
        itv: undefined,
        currentSlide: 0,
        vProcess: true,
        admin_password: '',
        pf: ''
      }
    },
    methods: {
      handleBeforeChange(event, slick, currentSlide, nextSlide) {
        this.currentSlide = nextSlide;
      },
    },
    computed: {
      progress() {
        return `width:${this.percent}%`;
      }
    }

  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .processing-container
    padding 160px 16px

  .img-x
    width 42px
    height 42px

  .progress-bar
    width 562px
    height 10px
    border-radius 50px
    background-color $gray2

  .progress
    height 100%
    background-color $primary
    border-radius 50px

  .indicator
    display flex
    justify-content center
    align-items center
    gap 8px

  .dot
    width 8px
    height 8px
    border-radius 100%
    background-color $gray1

  .dot-active
    background-color $main

  .slick-container
    width 600px


  .title
    font-size 28px
    font-weight 500

  .box-account
    background-color $gray3
    border-radius 12px
    padding 24px 40px
    display grid
    grid-template-columns 60px 1fr
    grid-gap 10px
    align-items center
    text-align left

  .button-admin
    width 100%
    height 52px
    font-size 16px
    font-weight 500
    margin-top 71px


  @media (max-width: 1024px)
    .processing-container
      padding 100px 16px

    .slick-container
      width 100%

    .title
      font-size 24px
      font-weight 500
</style>
